import React from 'react'
import sofa from '../assets/imgs/sofa.jpg'
export const Toparea = () => {
    return (
        <div className='main-body'>
            <div className="navbar vt captx">
                <div className='usc'>$USEDCOUCH</div>
                <a href="https://t.me/usedcouch_portal">telegram</a>
                <a href="">dexscreener</a>
                <a href="https://twitter.com/usedcouchsol">twitter</a>
            </div>
            <div className="body">
                <p className="txt uptx uni">Slightly used couch for sale</p>
                <img src={sofa} alt="sofa" className='sofa' />
            </div>
        </div>
    )
}
